import { TokenBalance } from '@frontend/app/(routes)/send/balances/route';
import { ChainActivity } from '@frontend/actions/detectChainActivity';
import { Quote } from '@frontend/common';
import { SwapVenue } from '@frontend/shared/routefinder/venues';
import { TokenInResponse } from '@frontend/client/hooks/tokenIn';
import { IdResolution, SavedLink, TokenValue } from '@frontend/types';
import { Address, WalletCapabilities } from 'viem';
import { create } from 'zustand';
import { YappConfig } from '@frontend/actions/resolveYapp';
import { YodlPaymentPriceFeeds } from '@frontend/shared/pricefeeds';

export type FundingSource = 'transfer' | 'swap';

export const validThemeAccents = [
  'gray',
  'gold',
  'bronze',
  'brown',
  'yellow',
  'amber',
  'orange',
  'tomato',
  'red',
  'ruby',
  'crimson',
  'pink',
  'plum',
  'purple',
  'violet',
  'iris',
  'indigo',
  'blue',
  'cyan',
  'teal',
  'jade',
  'green',
  'grass',
  'lime',
  'mint',
  'sky',
];
export const validThemeGrays = [
  'auto',
  'gray',
  'mauve',
  'slate',
  'sage',
  'olive',
  'sand',
];
export type YodlTheme = {
  accent:
    | 'gray'
    | 'gold'
    | 'bronze'
    | 'brown'
    | 'yellow'
    | 'amber'
    | 'orange'
    | 'tomato'
    | 'red'
    | 'ruby'
    | 'crimson'
    | 'pink'
    | 'plum'
    | 'purple'
    | 'violet'
    | 'iris'
    | 'indigo'
    | 'blue'
    | 'cyan'
    | 'teal'
    | 'jade'
    | 'green'
    | 'grass'
    | 'lime'
    | 'mint'
    | 'sky';
  gray: 'auto' | 'gray' | 'mauve' | 'slate' | 'sage' | 'olive' | 'sand';
};

export const EMPTY_TOKEN_IN = {
  amount: {
    value: 0n,
    decimals: 1,
    formatted: '0',
  },
  error: null,
  isLoading: false,
} as TokenInResponse;

const initialState = {
  handle: undefined,
  yd: undefined, // yodl id to track meta data
  walletCapabilities: undefined,
  theme: {
    accent: 'indigo',
    gray: 'auto',
  } as YodlTheme,
  // URL params
  isPlaywright: false,
  // Receiver
  receiver: undefined,
  idResolution: undefined,
  receiverBalances: [],
  chainActivities: undefined,
  // Wallet Connection
  sender: undefined,
  senderInfo: undefined,
  senderBalances: [],
  // TokenOut Dialog
  invoiceAmount: undefined, // what the user enters
  invoiceCurrency: 'USD', // what currency the user selects

  pricefeeds: undefined, // pricefeed (recently looked up)
  exchangeRate: undefined,
  pricefeed0: undefined, // pricefeed (recently looked up)

  tokenOutSymbol: undefined, // the symbol (USDC) that user selects (works cross-chain)
  tokenOut: undefined, // the chain-specific token
  tokenOutAmount: undefined, // the tokenOutAmount calculated from invoiceAmount x priceFeeds
  // TokenIn Dialog
  tokenIn: undefined, // the token selected by user
  tokenInAmount: EMPTY_TOKEN_IN,
  fundingSource: 'transfer' as FundingSource,
  swapVenue: undefined,
  swapQuote: undefined,
  // Other
  savedLinks: [],
  urlConfig: {} as PaymentConfig,
  yapp: undefined,
  yappTxAccessKey: undefined,
  yappConfigs: [],
  recentTransactionHash: undefined, // Used to send the transaction hash to the receiver to generate a link
};

export type PaymentConfig = {
  amount?: number;
  currency?: string;
  chainIds?: number[];
  tokenSymbols?: string[];
  memo?: string;
};

type YodlPaymentStoreType = {
  yd: string | undefined; // bigint
  isPlaywright: boolean;
  walletCapabilities: WalletCapabilities | undefined;
  recentTransactionHash?: string;
  theme: YodlTheme;
  handle: string | undefined;
  receiver: Address | undefined;
  idResolution: IdResolution | undefined;
  sender: Address | undefined;
  senderInfo: any;
  // useful to remember "previous" token when switching chains
  tokenOutSymbol: string | undefined;
  tokenOut: Address | undefined;
  // invoiceAmount is entered manually by the user. unlikely to be bigint-material
  invoiceAmount: number | undefined;
  invoiceCurrency: string;
  pricefeeds: YodlPaymentPriceFeeds[] | undefined;

  exchangeRate: bigint | undefined;
  // pricefeed0 converts the users selected invoiceCurrency into USD
  pricefeed0: YodlPaymentPriceFeeds | undefined;

  tokenIn: Address | undefined;
  tokenInAmount: TokenInResponse;
  tokenOutAmount: TokenValue | undefined;
  fundingSource: FundingSource;
  savedLinks: SavedLink[];
  swapVenue: SwapVenue | undefined;
  swapQuote: Quote | undefined;

  chainActivities: ChainActivity[] | undefined;
  senderBalances: TokenBalance[];
  receiverBalances: TokenBalance[];

  urlConfig: PaymentConfig;
  yapp: any;
  yappTxAccessKey: string | undefined;
  yappConfigs: YappConfig[];

  setYd: (yd: string) => void;
  setYapp: (yapp: any) => void;
  setYappConfigs: (yappConfigs: any) => void;
  setYappTxAccessKey: (yappTxAccessKey: string | undefined) => void;
  setWalletCapabilities: (
    walletCapabilities: WalletCapabilities | undefined,
  ) => void;
  setTheme: (theme: YodlTheme) => void;
  setHandle: (handle: string | undefined) => void;
  setPlaywright: (isPlaywright: boolean) => void;
  setUrlConfig: (urlConfig: PaymentConfig) => void;
  setReceiver: (address: Address) => void;
  setIdResolution: (idResolution?: IdResolution) => void;
  setSender: (address: Address) => void;
  setSenderBalances: (balances: TokenBalance[]) => void;
  setReceiverBalances: (balances: TokenBalance[]) => void;
  setTokenOutSymbol: (symbol: string) => void;
  setTokenOut: (address: Address) => void;
  setTokenIn: (address: Address | undefined) => void;
  setInvoiceAmount: (invoiceAmount: number | undefined) => void;

  setInvoiceCurrency: (currency: string) => void;
  setPricefeeds: (pricefeeds: YodlPaymentPriceFeeds[] | undefined) => void;
  setExchangeRate: (exchangeRate: bigint | undefined) => void;

  setFundingSource: (fundingSource: FundingSource) => void;
  setTokenInAmount: (tokenInAmount: TokenInResponse) => void;
  setTokenOutAmount: (tokenOutAmount: TokenValue | undefined) => void;

  resetChainActivities: () => void;
  setChainActivities: (chainActivities: ChainActivity[]) => void;

  setSwapQuote: (swapQuote: Quote | undefined) => void;
  setSwapVenue: (swapVenue: SwapVenue | undefined) => void;
  setSavedLinks: (savedlinks: SavedLink[]) => void;

  setRecentTransactionHash: (hash: string | undefined) => void;
};

// priceFeeds <- [invoiceCurrency, tokenOut]
// tokenOutAmount <- [invoiceAmount, priceFeeds, tokenOut]

export const useYodlStore = create<YodlPaymentStoreType>((set, get) => ({
  ...initialState,
  setYd: (yd) => set({ yd }),
  setYapp: (yapp) => set({ yapp }),
  setYappConfigs: (yappConfigs) => set({ yappConfigs }),
  setYappTxAccessKey: (yappTxAccessKey) => set({ yappTxAccessKey }),
  setWalletCapabilities: (walletCapabilities) => set({ walletCapabilities }),
  setTheme: (theme: YodlTheme) => set({ theme }),
  setUrlConfig: (urlConfig) => set({ urlConfig }),
  setHandle: (handle) => set({ handle }),
  setPlaywright: (isPlaywright) => set({ isPlaywright }),
  setReceiver: (receiver) => set({ receiver }),
  setIdResolution: (idResolution) => set({ idResolution }),
  setSender: (sender) => set({ sender }),
  setSenderBalances: (senderBalances) => set({ senderBalances }),
  setReceiverBalances: (receiverBalances) => set({ receiverBalances }),
  setTokenOutSymbol: (tokenOutSymbol) => set({ tokenOutSymbol }),
  setTokenOut: (tokenOut) => set({ tokenOut }),
  setTokenIn: (tokenIn) => set({ tokenIn }),
  setTokenInAmount: (tokenInAmount) => set({ tokenInAmount }),
  setTokenOutAmount: (tokenOutAmount) => set({ tokenOutAmount }),
  // Save invoice amount as integer
  setInvoiceAmount: (invoiceAmount) => set({ invoiceAmount }),

  setInvoiceCurrency: (invoiceCurrency) => set({ invoiceCurrency }),
  setPricefeeds: (pricefeeds: YodlPaymentPriceFeeds[] | undefined) =>
    set({ pricefeeds }),
  setExchangeRate: (exchangeRate: bigint | undefined) => set({ exchangeRate }),

  setFundingSource: (fundingSource: FundingSource) => set({ fundingSource }),
  setSwapQuote: (swapQuote: Quote | undefined) => set({ swapQuote }),
  setSwapVenue: (swapVenue: SwapVenue | undefined) => set({ swapVenue }),

  resetChainActivities: () => set({ chainActivities: undefined }),
  setChainActivities: (chainActivities: ChainActivity[]) =>
    set({ chainActivities }),
  setSavedLinks: (savedLinks: SavedLink[]) => set({ savedLinks }),
  setRecentTransactionHash: (recentTransactionHash) =>
    set({ recentTransactionHash }),
}));

export const paymentStore = useYodlStore;
