import {
  getChain,
  getFiatFeeds,
  getLatestRouter,
  YODL_ROUTER_ABIS,
} from '@yodlpay/tokenlists';
import {
  useAccount,
  useChains as useWagmiChains,
  useChains,
  useSwitchChain,
} from 'wagmi';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { YodlDataApiClient } from '@frontend/client/clients/yodlDataApi';
import { Address, Chain } from 'viem';
import { useYodlStore } from '@frontend/client/contexts/useYodlStore';
import { detectChainActivity } from '@frontend/actions/detectChainActivity';
import { SavedLink as SavedLinkSchema } from '@frontend/shared/validation';
import { chainById, resolveChainIdOrShortName } from '@frontend/utils/chains';
import { SwapVenue } from '@frontend/shared/routefinder/venues';

export const PRECISION = 10n ** 18n;

export function getRouterAbi(version: string) {
  return YODL_ROUTER_ABIS[version];
}

export const getRouterAddress = (chainId: number) => {
  return getLatestRouter(chainId).address as Address;
};

// Lookup dexes configured in yodl-config and turn them into SwapVenues
export const swapVenuesOfChain = (chain: Chain) => {
  return getChain(chain.id).dexes as SwapVenue[];
};

export const useChainInfo = () => {
  const { chain } = useAccount();

  if (!chain) return { chain, chainInfo: undefined, router: undefined };

  const { chainInfo } = chainById(chain.id);
  const router = getLatestRouter(chain.id);

  return { chain, chainInfo, router };
};

export const USE_EXTERNAL_CURRENCIES = ['THB'];

export const useInvoiceCurrencies = () => {
  const { chainInfo } = useChainInfo();

  if (!chainInfo) return [];
  const feedMap = new Set();
  getFiatFeeds(chainInfo.chainId).forEach(
    (f) => f.input && feedMap.add(f.input),
  );
  USE_EXTERNAL_CURRENCIES.forEach((c) => feedMap.add(c));

  return Array.from(feedMap) as string[];
};

export function useDebounce<T>(value: T, delay = 500) {
  const [debouncedValue, setDebouncedValue] = useState<T>(value);
  const [timestamp, setTimestamp] = useState(Date.now());

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
      setTimestamp(Date.now());
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return { debouncedValue, timestamp };
}

export const useChainActivities = (address: Address | undefined) => {
  // if not active
  const [loading, setLoading] = useState(true);
  const [isPending, setPending] = useState(!!address);
  const [error, setError] = useState<string | null>(null);

  const chainActivities = useYodlStore((s) => s.chainActivities);
  const setChainActivities = useYodlStore((s) => s.setChainActivities);

  const refreshChainActivities = useCallback(() => {
    async function fetch() {
      if (!address) {
        return;
      }
      setLoading(true);
      setError(null);
      const activities = await detectChainActivity(address);
      if (!activities) {
        setLoading(false);
        return;
      }

      setChainActivities(activities || []);
      setPending(false);
      setLoading(false);
    }
    fetch();
  }, [address, setChainActivities]);

  useEffect(() => {
    setPending(!!address);
    refreshChainActivities();
  }, [address, refreshChainActivities]);

  return {
    chainActivities,
    isPending,
    isLoading: loading,
    error: error,
    isError: !!error,
  };
};

export const useSavedLinks = () => {
  const [savedLinks, setSavedLinks] = useYodlStore((state) => [
    state.savedLinks,
    state.setSavedLinks,
  ]);

  useEffect(() => {
    const savedLinksString = localStorage.getItem('savedLinks');
    if (savedLinksString) {
      try {
        const savedLinksObj = JSON.parse(savedLinksString);
        SavedLinkSchema.parse(savedLinksObj);
        setSavedLinks(savedLinksObj);
      } catch (e) {
        console.error('Error: Unable to parse savedLinks');
      }
    }
  }, []);

  return savedLinks;
};

export const useSavedLink = () => {
  const savedLinks = useYodlStore((state) => state.savedLinks);
  const recipientIdentifier = useYodlStore((state) => state.idResolution);
  const setSavedLinks = useYodlStore((state) => state.setSavedLinks);

  useEffect(() => {
    const savedLinksString = localStorage.getItem('savedLinks');
    if (savedLinksString) {
      try {
        const savedLinksObj = JSON.parse(savedLinksString);
        SavedLinkSchema.parse(savedLinksObj);
        setSavedLinks(savedLinksObj);
      } catch (e) {
        console.error('Error: Unable to parse savedLinks');
      }
    }
  }, []);

  const savedLink = useMemo(() => {
    if (recipientIdentifier && savedLinks) {
      const link = savedLinks.find(
        (link) => link.identifier === recipientIdentifier.handle,
      );
      return link;
    }
    return null;
  }, [savedLinks, recipientIdentifier]);

  return savedLink;
};

export const useConfigurableChains = () => {
  const chains = useWagmiChains();
  const [userDefinedChains, setChains] = useState(chains);
  const configureChains = useCallback(
    (userSuppliedChains: number[]) => {
      const _chains = chains.filter((c) => userSuppliedChains.includes(c.id));
      //@ts-ignore
      setChains(_chains);
    },
    [setChains, chains],
  );
  return [userDefinedChains, configureChains];
};

export const useSelectableChains = () => {
  const chains = useChains();
  const { chain: currentChain } = useChainInfo();
  const { switchChain } = useSwitchChain();

  const identifier = useYodlStore((state) => state.handle);
  const receiver = useYodlStore((s) => s.receiver);
  const urlConfig = useYodlStore((s) => s.urlConfig);
  const idResolution = useYodlStore((s) => s.idResolution);

  const { chainActivities, isLoading, isPending } =
    useChainActivities(receiver);

  const [checkedCurrencies, setCheckedCurrencies] = useState({});

  const activeChainIds =
    chainActivities?.filter((c) => c.hasBalance).map((c) => c.chainId) || [];

  const chainId = currentChain?.id?.toString() ?? '';

  const setChain = (id: string) => {
    const selectedChainId = Number(id);
    switchChain({ chainId: selectedChainId });
  };

  let allChains = chains.map((c) => c);
  let explicitylEnabledChains: Chain[] | undefined;

  let idConfig = idResolution?.config;
  if (idConfig) {
    const filteredChainIds = idConfig.chains?.map(resolveChainIdOrShortName);
    if (filteredChainIds && filteredChainIds.length > 0) {
      explicitylEnabledChains = chains.filter((c) =>
        filteredChainIds?.includes(c.id),
      );
      if (filteredChainIds) {
        allChains = allChains.filter((c) => filteredChainIds.includes(c.id));
      }
    }
  }

  const urlChainIds = urlConfig?.chainIds;
  if (urlChainIds) {
    if (explicitylEnabledChains) {
      explicitylEnabledChains = explicitylEnabledChains.filter((c) =>
        urlChainIds.includes(c.id),
      );
    } else {
      explicitylEnabledChains = allChains.filter((c) =>
        urlChainIds.includes(c.id),
      );
    }
  }

  const selectableChains = explicitylEnabledChains || allChains;

  const isCurrentChainSelectable =
    currentChain && selectableChains.includes(currentChain);

  const chainIdIfSelectable = isCurrentChainSelectable ? chainId : undefined;

  const hasActivityOnChain = (chain: Chain) => {
    return activeChainIds.includes(chain.id);
  };

  const hasExplicitlyEnabledChain = (chain: Chain) => {
    return explicitylEnabledChains && explicitylEnabledChains.includes(chain);
  };

  const hasActivityOrExplicitlyEnabled = (chain: Chain) => {
    return hasActivityOnChain(chain) || hasExplicitlyEnabledChain(chain);
  };

  let chainHasActivityByReceiver =
    !!currentChain && hasActivityOrExplicitlyEnabled(currentChain);

  let isDataLoadedAndChainNotUsed = false;

  if (identifier && !isLoading) {
    isDataLoadedAndChainNotUsed = !chainHasActivityByReceiver;
  }

  return {
    selectableChains,
    isCurrentChainSelectable,
    chainIdIfSelectable,
    isDataLoadedAndChainNotUsed,
    isLoading,
    isPending,
    setChain,
    currentChain,
    hasActivityOrExplicitlyEnabled,
  };
};
