import { GNOSIS_MULTICALL_ADDRESS } from '@frontend/common';
import type { TokenValue } from '@frontend/types';
import type { TokenInfo } from '@yodlpay/tokenlists';
import { getTokenBySymbol } from '@yodlpay/tokenlists';
import {
  Address,
  MulticallParameters,
  PublicClient,
  formatUnits,
  type Chain,
} from 'viem';
import * as chains from 'viem/chains';
import { gnosis } from 'viem/chains';
import { SUPPORTED_CHAINS } from '@frontend/common';
import { isSupportedChain } from '@frontend/utils/chains';

export const NATIVE_TOKEN_ADDRESS =
  '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE' as Address;

export const filterSearchParams = (
  tokens: string | string[] | undefined,
  chains: string | string[] | undefined,
) => {
  tokens = tokens ? tokens : [];
  tokens = Array.isArray(tokens) ? tokens : [tokens];
  chains = chains ? chains : [];
  chains = Array.isArray(chains) ? chains : [chains];

  const _chains = chains.map((c) => parseInt(c));
  const supportedChainIds = SUPPORTED_CHAINS.map((c) => c.id as number);
  const validChains =
    _chains.length === 0
      ? supportedChainIds
      : _chains.filter((c) => supportedChainIds.includes(c));

  const filteredTokens = new Set<string>();
  const filteredChains = new Set<number>();
  for (const token of tokens) {
    for (const chain of validChains) {
      if (getTokenBySymbol(token, chain)) {
        filteredChains.add(chain);
        filteredTokens.add(token);
      }
    }
  }

  return [
    Array.from(filteredTokens),
    Array.from(filteredChains)
      .sort((a, b) => a - b)
      .map((c) => c.toString()),
  ];
};

// * true if both addresses match (lowercase)
// * false if both addresses are undefined (something weird happening)
export function isAddressMatching(
  addr1: string | undefined | null,
  addr2: string | undefined | null,
) {
  if (!addr1 && !addr2) {
    // console.log("isAddressMatching compares to undefined addresses. returning false")
    return false;
  } else {
    return addr1?.toLowerCase() == addr2?.toLowerCase();
  }
}

export const getViemChain = (id: number): Chain | undefined => {
  return Object.values(chains).find((x) => x.id === id);
};

export function lowerCaseEquals(str0: string, str1: string) {
  return str0.toLowerCase() === str1.toLowerCase();
}

export const multicallWithGnosis = async (
  client: PublicClient,
  args: MulticallParameters,
) => {
  // All other supported chains should have a multicall address
  if (client.chain?.id !== gnosis.id && !client.chain?.contracts?.multicall3) {
    throw new Error(
      `Multicall does not exist for chain id ${client.chain?.id}`,
    );
  }
  return await client.multicall({
    ...args,
    multicallAddress:
      client.chain?.id === gnosis.id
        ? GNOSIS_MULTICALL_ADDRESS
        : client.chain?.contracts?.multicall3?.address,
  });
};

export const invoiceAmountToTokenValue = (
  invoiceAmount: number | undefined,
  exchangeRate: bigint,
  tokenOutInfo: TokenInfo,
) => {
  // We subtract 2 as we assume the amount is already in minor mode, i.e. 2 decimals
  // Theoretically this could be adjusted to tokenOut.decimals.
  const invoiceAmountAdjusted = invoiceAmount
    ? BigInt(Math.round(invoiceAmount * 10 ** (tokenOutInfo.decimals - 2)))
    : 0n;

  // for Ether and other decimals == 18 coins, this multiplier is simply 1
  const exchangeRateMultiplier = BigInt(10 ** (18 - tokenOutInfo.decimals));

  const value =
    (invoiceAmountAdjusted * exchangeRate) /
    exchangeRateMultiplier /
    BigInt(10 ** tokenOutInfo.decimals);

  const decimals = tokenOutInfo.decimals;
  const formatted = formatUnits(value, decimals);

  return {
    value,
    decimals,
    formatted,
  } as TokenValue;
};
