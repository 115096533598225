// import node_modules
import type { ChainInfo, TokenInfo } from '@yodlpay/tokenlists';
import { getChain } from '@yodlpay/tokenlists';
import { type Chain } from 'viem';
import { getViemChain } from './tokenHelpers';
import {
  isValidNumber,
  SUPPORTED_CHAINS,
  SupportedChainId,
} from '@frontend/common';
import parseTokenImages from '@frontend/utils/parseImages';

/**
 ```
 "ETH" => 1
 "eth" => 1
 "1" => 1
 foobar = undefined
 ```
*/
export function resolveChainIdOrShortName(
  chainIdOrShortName: string,
): number | undefined {
  if (isValidNumber(chainIdOrShortName)) {
    return Number(chainIdOrShortName);
  } else {
    return CHAIN_SHORT_NAMES[chainIdOrShortName.toLowerCase()];
  }
}

export const CHAIN_SHORT_NAMES = {
  eth: 1,
  oeth: 10,
  matic: 137,
  gho: 100,
  arb1: 42161,
  base: 8453,
};

export const chainById = (
  id: number,
): { viemChain: Chain; chainInfo: ChainInfo } => {
  const viemChain = getViemChain(id);
  const _chainInfo = getChain(id);

  if (!viemChain) throw `viemChain not found for id=${id}`;
  if (!_chainInfo) throw `chainInfo not found for id=${id}`;

  const chainInfo = {
    ..._chainInfo,
    logoUri: parseTokenImages(_chainInfo.logoUri) || '',
  };

  if (!chainInfo.logoUri) {
    throw `chainInfo.logoUri not found for id=${id}`;
  }

  return {
    viemChain,
    chainInfo,
  };
};

export const isSupportedChain = ({ chainId }: TokenInfo) =>
  SUPPORTED_CHAINS.some(({ id }) => id === (chainId as SupportedChainId));
