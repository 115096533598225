/**
 * Github won't let us to fetch the images without token so we need to replace the url with the local path.
 * This is a temporary workaround until we find a better solution.
 *
 * @param src - The source of the image
 * @returns The local path of the image
 */

const parseTokenImagesWithBase = (baseUrl: string, src?: string) => {
  const url = src?.replace(
    'https://raw.githubusercontent.com/yodlpay/tokenlists/main/',
    `${baseUrl}/assets/`,
  );

  return url;
};

const parseTokenImages = (src?: string) => {
  const url = src?.replace(
    'https://raw.githubusercontent.com/yodlpay/tokenlists/main/',
    `/assets/`,
  );

  return url;
};

export default parseTokenImages;
export { parseTokenImagesWithBase };
