'use client';

import { connectorWalletIcon, truncateAddress } from '@frontend/common';
import { useYodlStore } from '@frontend/client/contexts/useYodlStore';
import { Button, Flex } from '@radix-ui/themes';
import { ConnectButton, WalletButton } from '@rainbow-me/rainbowkit';
import Image from 'next/image';
import { useAccount } from 'wagmi';

export const CustomConnect = ({ header = false }) => {
  const isPlaywright = useYodlStore((state) => state.isPlaywright);

  return (
    <>
      <ConnectButton.Custom>
        {({
          account,
          chain,
          openAccountModal,
          openChainModal,
          openConnectModal,
          authenticationStatus,
          mounted,
        }) => {
          const truncatedEthAddress =
            account && truncateAddress(account.address, 2);
          const ready = mounted && authenticationStatus !== 'loading';

          const connected =
            ready &&
            account &&
            chain &&
            (!authenticationStatus || authenticationStatus === 'authenticated');

          if (header) {
            return (
              <Flex>
                {(() => {
                  if (!connected) {
                    return (
                      <Button
                        variant="soft"
                        radius="none"
                        onClick={openConnectModal}
                        style={{
                          cursor: 'pointer',
                          fontWeight: 600,
                          height: '100%',
                        }}
                        size="2"
                        type="button"
                      >
                        Connect Wallet
                      </Button>
                    );
                  }

                  return (
                    <Button
                      variant="ghost"
                      onClick={openAccountModal}
                      style={{
                        padding: '0',
                        cursor: 'pointer',
                        margin: '0 30px 0 0',
                        width: '100%',
                        fontSize: '0.875rem',
                        height: '100%',
                      }}
                      type="button"
                      radius="none"
                    >
                      {truncatedEthAddress}
                    </Button>
                  );
                })()}
              </Flex>
            );
          } else {
            return (
              <div
                {...(!ready && {
                  'aria-hidden': true,
                  style: {
                    opacity: 0,
                    pointerEvents: 'none',
                    userSelect: 'none',
                  },
                })}
              >
                {(() => {
                  if (!connected) {
                    return (
                      <Button
                        onClick={openConnectModal}
                        variant="soft"
                        type="button"
                        style={{ width: '100%' }}
                        size="4"
                      >
                        Connect Wallet
                      </Button>
                    );
                  }

                  if (chain.unsupported) {
                    return (
                      <Button
                        onClick={openChainModal}
                        type="button"
                        radius="none"
                        style={{ width: '100%', height: '100%' }}
                        size="4"
                        className="hover:cursor-pointer"
                      >
                        Wrong network
                      </Button>
                    );
                  }

                  return null;
                })()}
              </div>
            );
          }
        }}
      </ConnectButton.Custom>
      {isPlaywright && <WalletButton data-testid="mockConnect" wallet="mock" />}
    </>
  );
};
